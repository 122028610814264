import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterContentInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SendEmailComponent } from '../send-email-issue-closed/send-email.component';
import { FileUpload } from 'primeng/fileupload';
import { SharedService } from 'src/app/services/shared.service';
import { Subscription } from 'rxjs';
import { IssueClosureConfirmationDialogComponent } from '../issue-closure-confirmation-dialog/issue-closure-confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { StaticvalueConstants } from 'src/app/reusables/constants/staticvalue.constants';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadAttachmentService } from 'src/app/services/download-attachment.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';


@Component({
  selector: 'app-issue-closed',
  templateUrl: './issue-closed.component.html',
  styleUrls: ['./issue-closed.component.css'],
  providers: [MessageService, DialogService, SharedService],
})
export class IssueClosedComponent implements OnInit, AfterContentInit, OnChanges {
  @Output() completedEventPassed = new EventEmitter();
  @Input() classDetails: boolean;
  @Input() formGroupName!: string;
  @Input() isSubmitted: boolean = false;
  @Input() issueCaptureMainForm: FormGroup;
  @Input() complaintData!: any;
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Close";
  @Input() fieldNameForFailureMode: string = "None";
  @ViewChild('calendar', { static: false })
  private calendar: any;
  dateControl!: FormControl;
  issueClosedForm!: FormGroup;
  issueCaptureForm!: FormGroup;
  whenHappenedText!: string;
  showCalendarDiv = false;
  previewSubmittedData: string = '';
  invalidDates!: Date;
  btnNameChange: string = 'Completed';
  submitted: boolean = false;
  shouldHideButton: boolean;
  changeBtnCompleted = false;
  issueStatusChange: string = '';
  classDetailsCompleted: boolean = false;
  subscription: Subscription;
  customerStatus: string = 'Active';
  classDetailsNew: boolean = false;
  enableResponseFeedback: boolean = false;
  descForResponse: any = '';
  // closureConfirmationStatus: string;
  enableDiv = false;
  isButtonDisabled = false;
  selectedDate: any;
  issueClosedDeadlineDateNotValid = false;
  issueCloseAttachment: any = [];
  acceptanceAttachments: any = [];
  acceptanceAttachmentsHolder: any = [];
  issueCloseAttachmentMerged: any = [];
  @Output() closeSaveEventPassed = new EventEmitter();
  @Output() returnToROEventPassed = new EventEmitter();
  @Output() completedExecutionPendingEventPassed = new EventEmitter();
  @Output() VerifySerialEventFromIssueClosePassed = new EventEmitter();
  feedBackValue: any;
  minDate: Date = new Date();


  checkBoxToolTipInfo =
    'Check this box only if you receive information from the customer that the solution cannot be executed immediately. You will also be required to provide the expected date of execution.';
  calendarToolTipInfo =
    'Click on "Select" button to provide the date of the expected implementation of the solution by the customer. The date should be entered in the following format: YYYY-MM-DD.';
  commentsToolTipInfo =
    'Feedback from the customer on the solution provided and/or the way issue was handled.';
  saveToolTipInfo = 'Saves the issue';
  returnToROToolTipInfo =
    'Use this button if you are not satisfied with the solution provided or more action is required. You will be prompted to complete an e-mail to the Resolution Owner where you need to explain why solution is not satisfactory and what additional actions you expect. Please note that Target Resolution &amp; Verification Date will be automatically extended by 7 days from the date of rejection.';
  execPendingBtnToolTipInfo =
    'Use this button to confirm that execution is pending by the customer.';
  completedBtnToolTipInfo = 'Completes the issue.';
  datePickerToolTipInfo =
    'Click on "Select" button to provide the date of the expected implementation of the solution by the customer. The date should be entered in the following format: YYYY-MM-DD.';
  removeText = 'Remove';

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null;
  disableSection: any = true;
  disableBtn: any = {};
  paramForAttachment: any;
  baseurl = environment.azure.baseUrl;
  downloadAllUrl = environment.azure.baseUrl + 'api/v1/Attachment/downloadmany?storeFilesNames=';
  isExternal: boolean = false;
  readonly maxLength = maxLengthConstants;
  reasonForChangeExecutionDate:boolean = false;

  ImageAcceptanceAttachments:any = [];
  OtherdocAcceptanceAttachments: any = [];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private dialogService: DialogService,
    private rootFormGroup: FormGroupDirective,
    private primengConfig: PrimeNGConfig,
    private ref: DynamicDialogRef,
    private sharedService: SharedService,
    private complaintDetailsService: ComplaintDetailsService,
    private datePipe: DatePipe,
    public authorizationService: AuthorizationService,
    private buttonVisibilityService: ButtonVisibilityServiceService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private downloadAttachementService:DownloadAttachmentService
  ) {
      // this.subscription = this.sharedService.enableDiv$.subscribe((response) => {
      //   debugger;
      //   this.enableDiv = response;
      // });
    }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  whenHappenedTextSelect() {
    this.whenHappenedText = this.issueClosedForm.controls['ExpectedExecutionDate']?.value ? 'Local: ' + formatDate(new Date(this.issueClosedForm.controls['ExpectedExecutionDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
  }

  ngOnInit(): void {
    this.paramForAttachment = { 0: '60 MB' };
    this.issueClosedForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
    this.issueCaptureForm = this.rootFormGroup.control.get('issueCaptureForm') as FormGroup;

    this.issueClosedForm?.controls['ExpectedExecutionDate']?.valueChanges.subscribe(
      (changedValue) => {
        if (changedValue) {
          const todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
          const updatedValue = this.datePipe.transform(
            changedValue,
            'yyyy-MM-dd'
          );
          const laterOneMonth = new Date(changedValue);
          const monthDate = this.datePipe.transform(
            laterOneMonth,
            'yyyy-MM-dd'
          );
          const d1 = new Date(
            this.datePipe.transform(new Date(), 'MM/dd/yyyy')
          );
          const d2 = new Date(
            this.datePipe.transform(laterOneMonth, 'MM/dd/yyyy')
          );

          const diffTime = d2.getTime() - d1.getTime();

          const diffDays = diffTime / (1000 * 60 * 60 * 24);

          if (diffDays < 0) {
            this.issueClosedDeadlineDateNotValid = true;
            this.issueClosedForm?.controls['ExpectedExecutionDate'].setErrors({
              incorrect: true,
            });
          } else {
            this.issueClosedDeadlineDateNotValid = false;
            this.issueClosedForm?.controls['ExpectedExecutionDate'].setErrors(null);
          }
          let oldExpectedExecutionDate = this.complaintData?.areaAcceptance?.expectedExecutionDate ? this.complaintData?.areaAcceptance?.expectedExecutionDate?.split('T',1)[0] : null;
          let latestExpectedExecutionDate = this.datePipe.transform(changedValue, 'yyyy-MM-dd');
          if(oldExpectedExecutionDate !== latestExpectedExecutionDate && oldExpectedExecutionDate !== null ){
            this.issueClosedForm.get('customerComments').addValidators(Validators.required);
            this.issueClosedForm.get('customerComments').updateValueAndValidity();
            this.reasonForChangeExecutionDate = true;
          }else{
            this.reasonForChangeExecutionDate = false;
            this.issueClosedForm.get('customerComments').clearValidators();
          }
        }
      }
    );

    // minDate function
    // let today = new Date();
    // this.invalidDates = today;

    // showCalendar function
    this.issueClosedForm
      .get('AwaitingCustomerExecution')
      ?.valueChanges.subscribe((val) => {
        this.showCalendarDiv = val;
      });

    this.issueClosedForm.get('sendFeedback')?.valueChanges.subscribe((resp) => {
      if (resp == 'Yes') {
        this.issueClosedForm.controls['CustomerFeedbackJustification'].disable();
        this.issueClosedForm.controls['isCustomerFeedbackJustification'].patchValue(false);
        this.issueClosedForm.patchValue({
          CustomerFeedbackJustification: null
        })
      }
      else if (resp == 'No') {
        this.issueClosedForm.controls['CustomerFeedbackJustification'].enable();
        this.issueClosedForm.controls['isCustomerFeedbackJustification'].patchValue(true);
        //External Bug User Story 9856: INC0695989|CHG0074430|Justification text in the customer feedback section is not getting saved and it is disappearing after saving.
        if(this.complaintData?.areaComplaintResolution?.customerFeedbackJustification) {
          this.issueClosedForm.patchValue({
           // CustomerFeedbackJustification: this.complaintData?.areaComplaintResolution?.customerFeedbackJustification
           CustomerFeedbackJustification:this.issueClosedForm.controls['CustomerFeedbackJustification'].value
          })
        }
      }
    })

    this.authorizationService.userAccessDetail.subscribe(async detail => {
      this.userAccessData = detail;
      this.attachmentDisabled();
      this.authorizationBtnDisable();
    });

    this.feedBackValue = [
      {
        id: 'Yes', value: 'Yes'
      },
      {
        id: 'No', value: 'No'
      }
    ]

    this.issueClosedForm?.controls['ExpectedExecutionDate']?.valueChanges.subscribe((changedValue) => {
      if (changedValue) {
        const updatedValue = this.datePipe.transform(changedValue, 'yyyy-MM-dd');
        this.issueClosedForm?.controls['ExpectedExecutionHiddenDate'].patchValue(new Date(updatedValue));
      }
    });
  }

  get f() {
    return this.issueClosedForm.controls;
  }

  ngAfterContentInit() {
    this.setFieldsValidation(this.ActionNameForComplaints);
    // console.log("====", this.complaintValidations);
    if (this.complaintData != undefined && this.issueClosedForm) {
      this.enableDiv = this.complaintData?.header?.complaintStatus==8 && this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerType==0 ? true : false;
      this.enableResponseFeedback = this.complaintData?.header?.complaintStatus==8 && this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerType==0 ? true : false
      && this.complaintData?.areaComplaintCollection?.survey?.surveyStatus !== 5;
      this.closureStatusMapping()
      this.issueClosedForm.patchValue({
        AwaitingCustomerExecution: this.checkBlank(this.complaintData?.areaAcceptance?.awaitingCustomerExecution),
        customerComments: this.checkBlank(
          this.complaintData?.areaAcceptance?.customerFeedback
        ),
        sendFeedback: this.complaintData?.areaComplaintResolution?.customerFeedbackRequired,
        // attachments: ['', [Validators.required]],
        ExpectedExecutionDate: this.checkBlank(
          this.complaintData?.areaAcceptance?.expectedExecutionDate?.split(
            'T',
            1
          )[0]
        ),
        customerContactStatus: this.checkBlank(
          StaticvalueConstants.CUSTOMER_CONTACT_STATUS
        ),
        // closureConfirmationStatus: this.complaintData?.survey?.surveyStatus,
        customerResponse: this.complaintData?.survey?.surveyResult
        /*this.checkBlank(
          StaticvalueConstants.CUSTOMER_RESPONSE
        ),*/
      });
      const customerTypeInit = this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerType == 0 ? 'External' : 'Internal'
      if (customerTypeInit == 'External') {
        this.isExternal = true;
      }
      else {
        this.isExternal = false;
      }
      if (this.complaintData?.areaComplaintResolution?.customerFeedbackRequired == 'No') {
        this.issueClosedForm.patchValue({
          CustomerFeedbackJustification: this.complaintData?.areaComplaintResolution?.customerFeedbackJustification
        })
      }
      this.whenHappenedText = this.issueClosedForm.controls['ExpectedExecutionDate']?.value ? 'Local: ' + formatDate(new Date(this.issueClosedForm.controls['ExpectedExecutionDate'].value), 'yyyy-MM-dd 05:29:59', 'en') : '';
      const imgFormatsArray = ['.jpg','.jpe','.png','.jpeg','.JPE','.JPEG','.PNG','.bmp','.BMP','.gif','.GIF'];
      this.acceptanceAttachments = this.complaintData?.areaAcceptance?.acceptanceAttachments;
      this.ImageAcceptanceAttachments = this.acceptanceAttachments.filter(i=> imgFormatsArray.includes(i.fileExtension));
      this.OtherdocAcceptanceAttachments = this.acceptanceAttachments.filter(i=> !imgFormatsArray.includes(i.fileExtension));
      this.acceptanceAttachmentsHolder = this.complaintData?.areaAcceptance?.acceptanceAttachments;
      this.issueCloseAttachmentMerged = this.complaintData?.areaAcceptance?.acceptanceAttachments;
      let attachmentData = { storFileName: '', displayName: '' };
      this.acceptanceAttachments.forEach(attachments => {
        attachmentData.storFileName = attachmentData?.storFileName + (attachmentData?.storFileName == '' ? '' : '_&_') + attachments?.storeFileName;
        attachmentData.displayName = attachmentData?.displayName + (attachmentData?.displayName == '' ? '' : '_&_') + attachments?.displayName;
      });
      this.downloadAllUrl = this.downloadAllUrl + encodeURIComponent(attachmentData?.storFileName) + '&displayNames=' + encodeURIComponent(attachmentData?.displayName);

      this.issueClosedForm.patchValue({
        uploadedAcceptanceAttachments: this.issueCloseAttachmentMerged
      });
    }

    if (this.issueClosedForm?.controls['AwaitingCustomerExecution']?.value && (this.issueClosedForm?.controls['ExpectedExecutionDate']?.value != null || this.issueClosedForm?.controls['ExpectedExecutionDate']?.value != '') && this.complaintData?.header?.complaintStatus === 7) {
      this.btnNameChange = 'Completed(Execution Pending)';
    }
    else {
      this.btnNameChange = 'Completed';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFieldsValidation(this.ActionNameForComplaints);
    if (this.issueCaptureForm) {
      const customerType = this.issueCaptureForm && this.issueCaptureForm.controls['CustomerType']?.value;

      if (customerType == 'External') {
        this.isExternal = true;
      }
      else {
        this.isExternal = false;
      }
    }
  }

  closureStatusMapping() {
    let surveyStatus = this.complaintData?.survey?.surveyStatus;
    const creationDate = this.complaintData?.survey?.creationDate ? formatDate(new Date(this.complaintData?.survey?.creationDate), 'yyyy-MM-dd', 'en') : null;
    const responseDate = this.complaintData?.survey?.responseDate ? formatDate(new Date(this.complaintData?.survey?.responseDate), 'yyyy-MM-dd', 'en'): null;
    let appendDate = '';
    let descValue = '';
    let descName = '';
    if(surveyStatus == 1) {
      descName = 'CustomerFeedbackSurvey_Sent';
      descValue = 'CustomerFeedbackSurvey_SentDesc';
      appendDate = creationDate
    }else if(surveyStatus == 2) {
      descName = 'CustomerFeedbackSurvey_DeliveryFailed';
      descValue = 'CustomerFeedbackSurvey_DeliveryFailedDesc';
      appendDate = responseDate
    }else if(surveyStatus == 3) {
      descName = 'CustomerFeedbackSurvey_Expired';
      descValue = 'CustomerFeedbackSurvey_ExpiredDesc';
    }else if(surveyStatus == 4) {
      descName = 'CustomerFeedbackSurvey_Responded';
      appendDate = responseDate
    }else if(surveyStatus == 5) {
      descName = 'CustomerFeedbackSurvey_NotSendDueParticipantUnsubscription';
      descValue = 'CustomerFeedbackSurvey_NotSendDueParticipantUnsubscriptionDesc';
    }
    if(descName !== '') {
      this.translateService.get(descName, { 0: appendDate }).subscribe((resp) => {
        if(resp) {
          this.issueClosedForm.patchValue({
            closureConfirmationStatus: resp,
            hiddenClosureConfirmationStatusCode: surveyStatus
          })
        }
      })
    }
    if(descValue !== '') {
      this.translateService.get(descValue, { 0: appendDate }).subscribe((resp) => {
        if(resp) {
          this.descForResponse = resp;
        }
      })
    }
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

  attachmentDisabled() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this.disableSection = false;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resource_Editor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
      }
      return this.disableSection
    }
  }

  authorizationBtnDisable() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this['notSaveClass_issueClose'] = true;
      this['notReturnToRo_issueClose'] = true;
      this['notShowCalComplete_issueClose'] = true;
      this['completed_issueClose'] = true;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableBtn['notSaveClass_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [204], this.userAccessData);
          this.disableBtn['notReturnToRo_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['notShowCalComplete_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          this.disableBtn['completed_issueClose'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [13], [204], this.userAccessData);
          break;

      }
    }
  }
  openCalendar(event: any, check: any) {
    this.calendar.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
  }

  onSubmit($event: Event) {
    this.previewSubmittedData = JSON.stringify(this.issueClosedForm.value);
    this.submitted = true;
    this.closeSaveEventPassed.emit({ submitted: true });

    if (this.issueCaptureMainForm.invalid) {
      // console.log('----', this.issueCaptureMainForm);
      $event.preventDefault();
    }
  }

  onCheckboxEnabled(event: any) {
    if (event.target.checked) {
      this.btnNameChange = 'Completed(Execution Pending)';
    } else {
      this.btnNameChange = 'Completed';
      this.issueClosedForm.controls['ExpectedExecutionDate'].reset();
    }
  }

  // Completed button functionality
  btnCompleted() {
    this.issueClosedForm.disable();
    this.isSubmitted = true;

    this.completedEventPassed.emit({ submitted: true });
  }

  // Execution Pending button functionality
  btnExecutionPending($event: Event) {
   /* this.issueClosedForm.controls['AwaitingCustomerExecution'].disable();
    this.issueClosedForm.controls['ExpectedExecutionDate'].enable();
    this.btnNameChange = 'Completed';
    this.shouldHideButton = true;
    this.changeBtnCompleted = true;*/
    // console.log('Working');
    this.completedEventPassed.emit({ submitted: true });

    //this.completedExecutionPendingEventPassed.emit({ submitted: true });

    // this.isSubmitted = true;
    // if (this.issueClosedForm.invalid) {
    //   console.log('-----', this.issueClosedForm);
    //   $event.preventDefault();
    // }
  }

  btnExecutionPendingExternal($event: Event) {
    this.isSubmitted = true;
    this.completedEventPassed.emit({ submitted: true });
    /*if (this.issueClosedForm.invalid) {
      // console.log('-----', this.issueClosedForm);
      $event.preventDefault();
    } else {
      this.issueClosedForm.controls['AwaitingCustomerExecution'].disable();
      this.issueClosedForm.controls['ExpectedExecutionDate'].enable();
      this.btnNameChange = 'Completed';
      this.shouldHideButton = true;
      this.changeBtnCompleted = true;
    }*/
  }

  // Email dialog open upon clicking on Return to RO button
  showEmailTemplate() {
    if (this.issueCaptureMainForm.controls["additionalSubjectForm"].get('isVerifiedSerial')?.value == false &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('BuCode')?.value == 'PGHV' &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('abbProductSerialNo')?.value) {
      this.spinner.hide();
      this.VerifySerialEventFromIssueClosePassed.emit({ actionName: "CoordinatorRequestsReturnToRO" });
    } else {
      this.ref = this.dialogService.open(SendEmailComponent, {
        header: 'Send Email',
        width: '80%',
        position: 'center',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'send-mail-issue-closed-pop',
        data: { complaintNo: this.complaintData.header.complaintNumber, complaintId: this.complaintData.header.id, role: this.userAccessData['roleCode'] }
      });

      this.ref.onClose.subscribe((result) => {
        if (result) {
          this.submitted = true;
          this.messageService.add({
            severity: 'success',
            summary: 'Message has been sent!',
          });
          this.returnToROEventPassed.emit({ submitted: true });

        }
      });
    }
  }

  showClosureDialog() {
    if (this.issueClosedForm.controls['sendFeedback']?.value == 'Yes') {
      this.ref = this.dialogService.open(
        IssueClosureConfirmationDialogComponent,
        {
          header: 'Complete issue',
          width: '43%',
          position: 'top',
          keepInViewport: true,
          autoZIndex: true,
          styleClass: 'issue-closure-pop',
        }
      );

      this.ref.onClose.subscribe((result) => {
        if (result) {
          this.classDetails = false;
          // this.issueClosedForm.controls['customerContactStatus'].patchValue('this.customerResponse');
         /* this.issueClosedForm.controls['AwaitingCustomerExecution'].disable(); */
          this.isButtonDisabled = true;
          this.classDetailsNew = true;
          const currentDate = new Date();
          const formattedDate = this.datePipe.transform(
            currentDate,
            'yyyy-MM-dd'
          );
          // this.closureConfirmationStatus = ` Closure confirmation sent on ${formattedDate}.`;
//           this.issueClosedForm.patchValue({
//             hiddenClosureConfirmationStatusCode: 0
//           })
          this.completedExecutionPendingEventPassed.emit({ submitted: true });
        }
      });
    }
    else {
     // this.issueClosedForm.disable();
      this.isSubmitted = true;

      this.completedEventPassed.emit({ submitted: true });
    }

  }
  // attchments
  myUploaderClose(createUploader, event) {
    let section = 'issue-resolution';
    for (var i = 0; i < event.files.length; i++) {
      if (i >= this.issueCloseAttachment?.length) {
        this.spinner.show();
        let formData = new FormData();
        formData.append('files', event.files[i]);
        this.complaintDetailsService
          .issueCaptureAttachmentSave(section, formData)
          .subscribe((response: any) => {
            let attachIdChange = response[0];
            attachIdChange.id = (attachIdChange?.id)?.toString();
            this.issueCloseAttachment.push(attachIdChange);
            this.issueCloseAttachmentMerged = [...this.acceptanceAttachmentsHolder, ...this.issueCloseAttachment];
            this.issueClosedForm.patchValue({
              uploadedAcceptanceAttachments: this.issueCloseAttachmentMerged
            });
            this.spinner.hide();
          },
          (error) => {
              this.spinner.hide();
              this.messageService.add({
                  severity: 'error',
                  summary: error ? error : 'Something went wrong!'
              });
          });
      }
    }
  }
  uploadedAcceptanceAttachmentRemove(attachmentId) {
    // console.log("uploadedAcceptanceAttachmentRemove=>",(this.acceptanceAttachmentsHolder.findIndex(attachment => attachment.id == this.acceptanceAttachments[acceptanceAttachmentIndex]?.id)));
    if ((this.acceptanceAttachmentsHolder.findIndex(attachment => attachment.id == attachmentId)) == -1) {
      //this.acceptanceAttachmentsHolder.push(this.acceptanceAttachments[acceptanceAttachmentIndex]);
    }
    else {
      this.acceptanceAttachmentsHolder = this.acceptanceAttachmentsHolder?.filter((attachment) => attachment?.id !== attachmentId);
    }
    this.issueCloseAttachmentMerged = [...this.acceptanceAttachmentsHolder, ...this.issueCloseAttachment];
    this.issueClosedForm.patchValue({
      uploadedAcceptanceAttachments: this.issueCloseAttachmentMerged
    });
  }

  checkAcceptanceRemoved(attachmentId) {
    // console.log("checkAcceptanceRemoved=>",(this.issueCloseAttachment.findIndex(attachment => attachment.id == this.acceptanceAttachments[acceptanceAttachmentIndex]?.id)));
    if ((this.acceptanceAttachmentsHolder.findIndex(attachment => attachment.id == attachmentId)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }
  removeFileClose(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = [this.issueCloseAttachment[index]?.storeFileName];
    this.complaintDetailsService
      .removeAttachments(filename)
      .subscribe((res) => {
        // this.issueCloseAttachment.splice(index, 1);
        this.issueCloseAttachment = this.issueCloseAttachment.filter(attachment => attachment?.storeFileName !== filename[0]);
        this.issueCloseAttachmentMerged = [...this.acceptanceAttachmentsHolder, ...this.issueCloseAttachment];
        this.issueClosedForm.patchValue({
          uploadedAcceptanceAttachments: this.issueCloseAttachmentMerged
        });
      });
  }

  setButtonVisibility(statusCodes: any) {
    let currentComplaintStatus = this.complaintData?.header?.complaintStatus;
    return this.buttonVisibilityService.setButtonVisibility(statusCodes, currentComplaintStatus);
  }
  setFieldsValidation(action) {
    //   console.log("if")
    for (const controlName in this.issueClosedForm?.controls) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueClosedForm.get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueClosedForm.get(controlName).clearValidators();
        this.issueClosedForm.get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index + 1;
      // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
      if (rule.depends != null) {
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if (fieldFailureModeValidation != "None" && rule.depends == "Validation" && rule.value == fieldFailureModeValidation) {
          validators.push(Validators.required);
          //   console.log("=======>>", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
          //   console.log("=======", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else {
          //   console.log("======= blank", validators);
          if (index == rules.length) {
            return validators;
          }
        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }

  downloadAttachementFromAPI(storeFileName, displayName) {
    this.downloadAttachementService.downloadAttachement(storeFileName, displayName);
  }
}
