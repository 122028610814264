<div class="send-email-site-action">
  <form [formGroup]="emailForm">
    <p-toast></p-toast>
    <div class="send-email">
      <div class="row mb-2">
        <label for="colFormLabelSm" class="col-sm-1 col-md-2 tagColumn col-form-label-sm alignSendMailTo">{{
          "CreateEmail_EmailTo" | translate }}<span class="asteriskRequired"
            *ngIf="this.emailForm.controls['to']?.validator"></span>
        </label>
        <div class="col-sm-8 col-md-8 sendToField" style="padding: 0px;">
          <p-autoComplete [forceSelection]="true" [minLength]=2 [suggestions]="filteredRecord"
            (onSelect)="selectto($event)" (onUnselect)="removeto($event)" class="substituteCustom border-0"
            styleClass="issueAlign"  type="text" formControlName="to"
            (completeMethod)="onRecipientChange($event)" field="selectedLabel" [multiple]="true"
            [ngClass]="isSendProcess && this.emailForm.controls['to'].invalid ? 'error-autocomplete' : ''">
            <ng-template let-filteredRecord pTemplate="selectedItem">
              <div (mouseover)="toGotFocus(filteredRecord?.tooltipInfo)" (mouseout)="toLostFocus()">
                {{filteredRecord?.selectedLabel }}</div>
              <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                                (click)="clearOwner()"></span> -->
            </ng-template>
            <ng-template let-filteredRecord pTemplate="item">
              <div class="flex align-items-center gap-2 mb-2">
                <div>{{ filteredRecord?.promptLabel }}</div>
              </div>
            </ng-template></p-autoComplete>
          <!-- <p-autoComplete [forceSelection]="true" formControlName="to" class="substituteCustom border-0"
                        [suggestions]="filteredRecord" (completeMethod)="onRecipientChange($event)" field="promptLabel" [multiple]="true">
                        <ng-template let-filteredRecord pTemplate="item">
                          <div class="flex align-items-center gap-2">
                            <div>{{ filteredRecord.promptLabel }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-filteredRecord pTemplate="selectedItem">
                          <div>{{filteredRecord?.selectedLabel }}</div>
                        </ng-template>
                      </p-autoComplete> -->
        </div>
      </div>
      <div class="row mb-2">
        <label for="colFormLabelSm" class="col-sm-1 col-md-2 tagColumn col-form-label-sm alignSendMailCC">{{
          "CreateEmail_EmailCC" | translate }}<span class="asteriskRequired"
            *ngIf="this.emailForm.controls['cc']?.validator"></span>
        </label>
        <div class="col-sm-8 col-md-8 sendToField" style="padding: 0px;">
          <p-autoComplete [forceSelection]="true" [minLength]=2 [suggestions]="filteredRecord"
            (onSelect)="selectcc($event)" (onUnselect)="removecc($event)" class="substituteCustom border-0" type="text"
            formControlName="cc" [suggestions]="filteredRecord" (completeMethod)="onRecipientChange($event)"
            field="selectedLabel" [multiple]="true"
            [ngClass]="isSendProcess && this.emailForm.controls['cc'].invalid ? 'error-autocomplete' : ''">
            <ng-template let-filteredRecord pTemplate="selectedItem">
              <div (mouseover)="ccGotFocus(filteredRecord?.tooltipInfo)" (mouseout)="ccLostFocus()">
                {{filteredRecord?.selectedLabel }}</div>
              <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                                (click)="clearOwner()"></span> -->
            </ng-template>
            <ng-template let-filteredRecord pTemplate="item">
              <div class="flex align-items-center gap-2 mb-2">
                <div>{{ filteredRecord?.promptLabel }}</div>
              </div>
            </ng-template></p-autoComplete>
          <!-- <p-autoComplete [forceSelection]="true" formControlName="cc" class="substituteCustom border-0"
                        [suggestions]="filteredRecord" (completeMethod)="onRecipientChange($event)" field="promptLabel" [multiple]="true">
                        <ng-template let-filteredRecord pTemplate="item">
                          <div class="flex align-items-center gap-2">
                            <div>{{ filteredRecord.promptLabel }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-filteredRecord pTemplate="selectedItem">
                          <div>{{filteredRecord?.selectedLabel }}</div>
                        </ng-template>
                      </p-autoComplete> -->
        </div>
      </div>
      <div class="row mb-2 forSubject">
        <label for="colFormLabelSm" class="col-sm-1 col-md-2 tagColumn col-form-label-sm">{{ "CreateEmail_EmailSubject" |
          translate }}<span class="asteriskRequired" *ngIf="this.emailForm.controls['subject']?.validator"></span>
        </label>
        <div class="col-sm-8 col-md-8" style="padding: 0px;">
          <input class="form-control form-control-sm customField " formControlName="subject" readonly="true"
            [ngClass]="isSendProcess && this.emailForm.controls['subject'].invalid ? 'error-inputbox' : ''">
        </div>
      </div>
      <div class="row mb-2 forTemplate">
        <label for="colFormLabelSm" class="col-sm-1 col-md-2 tagColumn col-form-label-sm">Template<span class="asteriskRequired"
            *ngIf="this.emailForm.controls['template']?.validator"></span>
        </label>
        <div class="col-sm-8 col-md-8" style="padding: 0px;"><select
            class="form-select form-control form-control-sm customField border" aria-label="Default select example"
            formControlName="template" (change)="template($event)"
            [ngClass]="isSendProcess && this.emailForm.controls['template'].invalid ? 'error-inputbox' : ''">
            <!-- <option value="19">ABB has recorded issue based on your feedback</option>
                        <option value="20">General</option>
                        <option value="21">Request for action</option> -->
            <option value={{a?.key}} *ngFor="let a of getUserMessageVariable">{{a?.value}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <h6 class="inner-heading1">{{ "ComplaintForm_AttachmentsSectionName" | translate }}</h6>
      </div>
      <div class="row mb-1 attach attachments-section">
        <div class="col-md-3 attachementmobile">
          <label class="specify">{{ "ComplaintForm_SpecifyAttachment" | translate:paramForAttachment }}<span
              class="asteriskRequired" *ngIf="this.emailForm.controls['attachment']?.validator"></span>
          </label>
        </div>
        <div class="col-md-8 selectfile">
          <p-fileUpload #createUploader name="files[]" chooseLabel="Select Files" [showCancelButton]="true"
            chooseIcon="pi pi-paperclip" removeStyleClass="p-overlaypanel1" [multiple]="true" chooseStyleClass="p-overlaypanel"
            [maxFileSize]="30000000" [auto]="true" class="" [customUpload]="true"
            (uploadHandler)="myUploader(createUploader,$event)">
            <ng-template let-file let-index pTemplate="file">
              <ul class="p-upload-files p-reset">
                <li class="p-file row">
                  <div class="col-md-10 col-sm-10" [pTooltip]="file?.name" [escape]="false" tooltipPosition="top"
                    tooltipStyleClass="toolTipClass">
                    <span class="p-filename">{{file?.name}}</span>
                  </div>
                  <div class="col-md-2 col-sm-2 text-end" [pTooltip]="removeText" [escape]="false" tooltipPosition="top"
                    tooltipStyleClass="toolTipClass">
                    <!-- <button type="button" class="widgetButtoSmall attachButton"
                                                (click)="removeFile(file ,createUploader)"> X </button> -->
                    <i class="fa fa-window-close closebtn" aria-hidden="true"
                      (click)="removeFile(file ,createUploader)"> </i>
                  </div>
                </li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
      <input type="hidden" formControlName="defaultto">
      <input type="hidden" formControlName="defaultcc">
      <p-editor formControlName="textareaBody" [style]="{height: '200px'}"
        [ngClass]="isSendProcess && this.emailForm.controls['textareaBody'].invalid ? 'error-editorbox' : ''">
      </p-editor>


      <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
        <button class="input widgetButtonMedium p-button-success " type="button" (click)="sendMail()"
          label="Top Left">{{ "CreateEmailForm_SendEmailDialogTitle" | translate }}</button>
        <button class="input widgetButtonMedium" type="button" (click)="onClose()">{{ "ButtonExitWithoutSaving" |
          translate }}</button>
      </div>
    </div>
  </form>
</div>
