<div>
    <h3 class="report-title">{{'Report_AllInOne'|translate}}</h3>
</div>
<form class="form-horizontal selectCheckbox report-continer all-in-one-report-section" role="form" name="rForm" [formGroup]="reportsForm">
    <div class="panel panel-default allInOneReportMainDiv">
        <div class="panel-heading">
            <ul class="nav nav-tabs nav-justified">
                <li
                    class="nav-item flex-sm-fill" (click)="setSelectedTab(constVariables.tab.byFilter)">
                    <a [ngClass]="{'active': currentSelectedTab === constVariables.tab.byFilter}" class="nav-link">{{'rpt_generate_for_filters_lbl'|translate}}</a>
                </li>
                <li (click)="setSelectedTab(constVariables.tab.byCcrpNo)"
                    class="nav-item flex-sm-fill">
                    <a [ngClass]="{'active': currentSelectedTab === constVariables.tab.byCcrpNo}" class="nav-link">{{'rpt_generate_for_ccrp_lbl'|translate}}</a>
                </li>
            </ul>
        </div>
        <div class="row selected-tab-align mt-20">
            <div *ngIf="currentSelectedTab === constVariables.tab.byFilter">
                <div class="col-md-12">
                </div>
                <div>
                    <div class="col-md-9 row-gap-top-issue-type row-gap-fold-align help-customer header-align-mobile mt-3 tab-align-lgscreen tab-align-xl-lgscreen">
                        <app-radiobuttonoptions class="radiobuttonsWidth label-alignment-all-in-one-report" [optionModel]="optionModel" title="Issue Type"
                            allMsg="{{'cc_opex_customertype_all'|translate}}"
                            firstOptionMsg="{{'cc_opex_customertype_ext'|translate}}"
                            secondOptionMsg="{{'cc_opex_customertype_internal'|translate}}" [inverted]="true"
                            labelClass="col-sm-2 fw-normal mt-1 hello" (optionModelChange)="handleOptionChange($event)">
                            ></app-radiobuttonoptions>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                        </div>
                    </div>
                </div>
                <div class="row maindiv">
                    <div class="col-md-6">
                        <div >
                            <div class="help-div">
                                <div class="form-group row-gap-align tab-align-landscape">
                                    <label for="resolveBusiness"
                                        class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'RPT_RU_DIV_LBL'|translate}}
                                    </label>
                                    <div class="col-sm-9 row-gap-fold-align">
                                        <div class="multiselectWidth multi-select-tab">
                                            <p-multiSelect class="custom-ms multiselectWidth-tab-align border-0 p-multiselect p-component"
                                                [options]="getresolverBusinessData" [resetFilterOnHide]="true"
                                                defaultLabel="{{'RPT_RU_DIV_LBL' | translate}}" formControlName="resolveBusiness"
                                                optionLabel="name" [filter]="true" [showToggleAll]="false" [showHeader]="true" display="chip"
                                                dropdownIcon="none" optionValue="id" ></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row-gap-align row-gap-xl-align-bu">
                                    <label for="resolveBu"
                                        class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'RPT_RU_BU_LBL'|translate}}
                                    </label>
                                    <div class="col-sm-9 row-gap-fold-align">
                                        <div class="multiselectWidth">
                                            <p-multiSelect class="custom-ms border-0 p-multiselect p-component" [resetFilterOnHide]="true"
                                                [options]="getresolverBuData" defaultLabel="{{'RPT_RU_BU_LBL'|translate}}"
                                                formControlName="resolveBu" optionLabel="name" [filter]="true" [showToggleAll]="false"
                                                [showHeader]="true" display="chip" (onChange)="resolverBuChange($event)"
                                                dropdownIcon="none" optionValue="id" ></p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row-gap-align row-gap-tab row-gap-xl-align-bu">
                                    <label for="resolvepg"
                                        class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_pg_lbl'|translate}}</label>
                                    <div class="col-sm-9 row-gap-fold-align">
                                        <div class="multiselectWidth">
                                            <p-multiSelect class="custom-ms border-0 p-multiselect p-component" [resetFilterOnHide]="true"
                                                [options]="filteredResloverPgData" defaultLabel="{{'rpt_ru_pg_ph'|translate}}"
                                                optionLabel="name" display="chip" [filter]="true" [showToggleAll]="false" [showHeader]="true"
                                                dropdownIcon="none" formControlName="resolvepg" optionValue="id">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row-gap-align row-gap-tab-resolver-ba row-gap-xl-align-ba">
                                    <div class="col-md-12 help-customer row-gap-fold-align">
                                        <app-radiobuttonoptions [optionModel]="optionModelResolver"
                                            title="{{'rpt_ru_ba_lbl'|translate}}" allMsg="{{'rpt_ru_ba_all'|translate}}"
                                            firstOptionMsg="{{'rpt_ru_ba_loc'|translate}}" formControlName=""
                                            secondOptionMsg="{{'rpt_ru_ba_glob'|translate}}" [inverted]="true"
                                            labelClass="col-sm-3 resolver-ba-align label-alignment-all-in-one-report fw-normal mt-1 me-2"
                                            (optionModelChange)=" handleOptionChangeGlobal($event)">
                                        </app-radiobuttonoptions>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align row-gap-align-tab-activities">
                                <label for="resolver"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_actv_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms  border-0 p-multiselect p-component"
                                            [options]="activitiesData" dropdownIcon="none"
                                            defaultLabel="{{'rpt_ru_actv_ph'|translate}}" [resetFilterOnHide]="true"
                                            [showHeader]="true" optionLabel="name" display="chip" [filter]="true" [showToggleAll]="false"
                                            formControlName="activities" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <div class="form-group row-gap-align row-gap-align-tab-desc-names">
                                <label for="activities"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_desc_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms  border-0 p-multiselect p-component"
                                            [options]="descriptiveNameData" dropdownIcon="none" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_ru_desc_ph'|translate}}" [maxSelectedLabels]="1"
                                            optionLabel="name" display="chip" [filter]="true" [showToggleAll]="false" [showHeader]="true"
                                            formControlName="descriptiveNames" optionValue="name">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="help-region">
                                <div class="form-group row-gap-align tab-align-lg-region">
                                    <label for="region"
                                        class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_reg_lbl'|translate}}</label>
                                    <div class="col-sm-9 row-gap-fold-align">
                                        <div class="multiselectWidth">
                                            <p-multiSelect class="custom-ms   border-0 p-multiselect p-component"
                                                [options]="regionData" dropdownIcon="none" [resetFilterOnHide]="true"
                                                defaultLabel="{{'rpt_ru_reg_ph'|translate}}"
                                                optionLabel="name" display="chip" [filter]="true" [showToggleAll]="false" [showHeader]="true"
                                                formControlName="region" (onChange)="resolverRegionChange($event)" optionValue="id">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row-gap-align tab-align-lg-country">
                                    <label for="resolverCountry"
                                        class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_country_lbl'|translate}}</label>
                                    <div class="col-sm-9 row-gap-fold-align">
                                        <div class="multiselectWidth">
                                            <p-multiSelect class="custom-ms  border-0 p-multiselect p-component"
                                                [options]="filteredCountriesData" [showHeader]="true"
                                                defaultLabel="{{'rpt_ru_country_ph'|translate}}"
                                                dropdownIcon="none" optionLabel="name" [showToggleAll]="false" display="chip" [filter]="true"
                                                formControlName="resolverCountry" [resetFilterOnHide]="true"
                                                (onChange)="resolverCountryChange($event)" optionValue="id">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-lg-city">
                                <label for="city"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_ru_city_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms  border-0 p-multiselect p-component"
                                            [options]="filteredCitiesData" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_ru_city_ph'|translate}}" dropdownIcon="none"
                                            optionLabel="name" display="chip" [showHeader]="true" [showToggleAll]="false" [filter]="true"
                                            formControlName="resolverCity" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                                <!-- Customer Region-->
                            </div>
                            <div class="form-group row-gap-align row-gap-align-tab-custpg tab-align-lg-custRegion">
                                <label for="customerpg"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_customer_region_lbl'|translate}}
                                </label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms  border-0 p-multiselect p-component"
                                            [options]="regionData" dropdownIcon="none" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_customer_region_ph'|translate}}" [showHeader]="true" optionLabel="name"
                                            display="chip" [showToggleAll]="false" [filter]="true" formControlName="customerpg"
                                            (onChange)="customerRegionChange($event)" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <!-- / Customer Region-->
                            <!-- Customer Country-->
                            <div class="form-group row-gap-align">
                                <label for="customercountry"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3  mt-1 text-end">{{'rpt_customer_country_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="filteredCustomerCountries" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_cust_country_ph'|translate}}"
                                            [dropdownIcon]="'none'" [showToggleAll]="false" optionLabel="name"
                                            display="chip" [filter]="true" formControlName="customercountry"
                                            (onChange)="customerCountryChange($event)" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <!-- Customer Country-->
                            <!-- Customer City-->
                            <div class="form-group row-gap-align tab-align-lg-custCity">
                                <label for="customercity"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_customer_city_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth ">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="filteredCustomerCities" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_customer_city_ph'|translate}}" [dropdownIcon]="'none'"
                                            [showToggleAll]="false" optionLabel="name" display="chip" [filter]="true"
                                            formControlName="customercity" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <!-- / Customer City-->
                            <div class="form-group row-gap-align row-gap-align-tab-custguid">
                                <label for="customerName"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_customer_name_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align" >
                                    <input type="hidden" placeholder="{{'rpt_customer_name_ph'|translate}}" />
                                    <div class="multiselectWidth select-customername">
                                        <p-autoComplete
                                            [forceSelection]="true"
                                            class="custom-ms border-0 p-multiselect p-component auto-class customer-guid"
                                            formControlName="customerName" [multiple]="true" type="text"
                                            placeholder="{{'rpt_customer_name_ph'|translate}}"
                                            [suggestions]="filteredCustomersData" [dropdownIcon]="'none'"
                                            (completeMethod)="filterCustomersData($event)"
                                            (onSelect)="customerSelect($event)" (onUnselect)="customerGuidsUnselect($event)" field="name" >
                                          <ng-template let-country pTemplate="item">
                                            <div class="flex align-items-center gap-2" style="padding: 0 10px;">
                                              <div style="font-weight: bold;">{{ country.name }}</div>
                                            </div>
                                            <div class="flex align-items-center gap-2" style="padding: 0 10px; white-space: break-spaces">
                                              <div>{{ country.addr }}, {{ country.iso }}</div>
                                            </div>
                                          </ng-template>
                                        </p-autoComplete>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row-gap-align">
                            <label for="inputPassword3"
                                class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-2 text-end">{{'rpt_nps_lbl'|translate}}</label>
                              <table class="classTable mt-1">
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                                <input formControlName="ccrpCheck" (click)="calculateTotal(1,$event)" id="ccrp" type="checkbox">
                                                <label class="checkbox-labels ms-1" for="ccrp">CCRP</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input formControlName="npxxx" (click)="calculateTotal(2,$event)" id="npxxx" type="checkbox">
                                                <label class="checkbox-labels ms-1" for="npxxx">NPX XX_T-X</label>
                                            </div>


                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input formControlName="npmxx" (click)="calculateTotal(4,$event)" id="npmxx" type="checkbox">
                                                <label class="checkbox-labels ms-1" for="npmxx">NPM XX_N-X</label>
                                            </div>

                                        </td>
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                                <input formControlName="sfdc" (click)="calculateTotal(8,$event)" id="sfdc" type="checkbox">
                                                <label class="checkbox-labels ms-1" for="sfdc">SFDC XX-S-X</label>
                                            </div>

                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input formControlName="topQI" (click)="calculateTotal(16,$event)" id="topQI" type="checkbox">
                                                <label class="checkbox-labels ms-1" for="topQI">Top QI</label>
                                           </div>
                                        </td>
                                    </tr>
                                </table>
                        </div>
                    </div>
                    <div class="col-md-6 right-align-field-section">
                        <div >
                            <div class="form-group row-gap-align tab-align-lgLvl1">
                                <label for="lv1"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_co_mgmnt_lv1_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="ownerManagerLv1Data" [dropdownIcon]="'none'"
                                            defaultLabel="{{'rpt_co_mgmnt_lv1_ph'|translate}}" [resetFilterOnHide]="true"
                                            [showToggleAll]="false" optionValue="id" (onChange)="level1Change($event)" optionLabel="name" display="chip"
                                            formControlName="lv1" >
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-lgLvl2">
                                <label for="lv2"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end tab-align-lgLvl2">{{'rpt_co_mgmnt_lv2_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="ownerManagerLv2Data" [dropdownIcon]="'none'" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_co_mgmnt_lv2_ph'|translate}}" [showToggleAll]="false"
                                            optionLabel="name" optionValue="id"  display="chip" (onChange)="level2Change($event)" [filter]="true"
                                             formControlName="lv2">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-lgLvl3">
                                <label for="lv3"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_co_mgmnt_lv3_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="ownerManagerLv3Data" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_co_mgmnt_lv3_ph'|translate}}" [showToggleAll]="false"
                                            optionLabel="name" display="chip" [filter]="true" [dropdownIcon]="'none'"
                                            formControlName="lv3" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-owner-region tab-align-mini-gap">
                                <label for="coregion"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_co_reg_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="regionData" defaultLabel="{{'rpt_co_reg_ph'|translate}}"
                                            [showToggleAll]="false" optionLabel="name" display="chip" [resetFilterOnHide]="true"
                                            [dropdownIcon]="'none'" [filter]="true" formControlName="coregion"
                                            (onChange)="ownerRegionChange($event)" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-lg-owner-country">
                                <label for="ownercountry"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_co_country_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="filteredOwnerCountries"
                                            defaultLabel="{{'rpt_co_country_ph'|translate}}" [resetFilterOnHide]="true"
                                            [dropdownIcon]="'none'" [showToggleAll]="false" optionLabel="name"
                                            display="chip" [filter]="true" formControlName="ownercountry"
                                            (onChange)="ownerCountryChange($event)" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row-gap-align tab-align-lg-owner-cities">
                                <label for="ownercity"
                                    class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_co_city_lbl'|translate}}</label>
                                <div class="col-sm-9 row-gap-fold-align">
                                    <input type="hidden" ui-select2-max="10" ui-select2="cityEmOptions"
                                         multiple
                                        data-placeholder="{{'rpt_co_city_ph'|translate}}" />
                                    <div class="multiselectWidth">
                                        <p-multiSelect class="custom-ms border-0 p-multiselect p-component"
                                            [options]="filteredOwnerCities" [resetFilterOnHide]="true"
                                            defaultLabel="{{'rpt_co_city_ph'|translate}}" [dropdownIcon]="'none'"
                                            [showToggleAll]="false" optionLabel="name" display="chip" [filter]="true"
                                            formControlName="ownercity" optionValue="id">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row-gap-align row-gap-align-tab-status">
                            <label for="status"
                                class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_cmp_status_lbl'|translate}}</label>
                            <div class="col-sm-9 row-gap-fold-align">
                                <div class="multiselectWidth">
                                    <p-multiSelect class="custom-ms border-0 p-multiselect p-component" [resetFilterOnHide]="true"
                                        [options]="issueStatusData" defaultLabel="{{'rpt_cmp_status_ph'|translate}}"
                                        [showToggleAll]="false" optionLabel="name" display="chip" [dropdownIcon]="'none'"
                                        [filter]="true" formControlName="status" optionValue="id">
                                    </p-multiSelect>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row-gap-align row-gap-align-tab-date-type">
                            <label for="datetype"
                                class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 text-end">{{'rpt_dt_kind_lbl'|translate}}</label>
                            <div class="col-sm-9 row-gap-fold-align">
                                <div class="multiselectWidth">
                                    <select class="multiselectWidth form-control"
                                        formControlName="dateType">
                                        <option value="CreationDate">{{'rpt_dt_kind_creation'|translate}}</option>
                                        <option value="SubmitDate">{{'rpt_dt_kind_submit'|translate}}</option>
                                        <option value="AssignDate">{{'rpt_dt_kind_assign'|translate}}</option>
                                        <option value="SolutionDate">{{'rpt_dt_kind_solsend'|translate}}</option>
                                        <option value="CompletionDate">{{'rpt_dt_kind_completion'|translate}}</option>
                                        <option value="TargetDate">{{'rpt_dt_kind_target_resolving'|translate}}</option>
                                        <option value="ExpectedExecutionDate">{{'rpt_dt_kind_expected_execution' |
                                            translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row-gap-align row-gap-align-tab-date-range">
                            <label for="inputpassword3" class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-1 align-date-range text-end">
                                {{'rpt_dt_range_lbl'|translate}}
                            </label>
                            <div>
                                <select class="col-sm-9 datemultiselectWidth form-control shadow-none" formControlName="dateRange">
                                    <option value="None">{{'rpt_dt_period_beetween'|translate}} </option>
                                    <option value="Last7Days">{{'rpt_dt_period_last7'|translate}}</option>
                                    <option value="Last30Days">{{'rpt_dt_period_last30'|translate}}</option>
                                    <option value="Last90Days">{{'rpt_dt_period_last90'|translate}}</option>
                                    <option value="Last365Days">{{'rpt_dt_period_last365'|translate}}</option>
                                    <option value="MonthToDate">{{'rpt_dt_period_month'|translate}}</option>
                                    <option value="QuarterToDate">{{'rpt_dt_period_quarter'|translate}}</option>
                                    <option value="YearToDate">{{'rpt_dt_period_year'|translate}}</option>
                                    <option value="WeekToDate">{{'rpt_dt_period_week'|translate}}</option>
                                </select>
                                <div class="row" *ngIf="reportsForm.get('dateRange').value == 'None'">
                                    <div class="col-md-12 col-lg-12 col-sm-12 my-1">
                                        <div class="datefield-report calendar-width1">
                                            <p-calendar dateFormat="yy-mm-dd" #calendar1 name="StartDate"
                                                [showIcon]="false" placement="bottom" [readonlyInput]="true" formControlName="rangeStartDate">
                                            </p-calendar>
                                        </div>
                                        <div class="datefield-report calendar-width1">
                                            <p-calendar dateFormat="yy-mm-dd" #calendar2 name="EndDate"
                                                [showIcon]="false" placement="bottom" formControlName="rangeEndDate"
                                                [readonlyInput]="true"
                                                [minDate]="invalidDates">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="reportsForm.get('dateRange').value != 'None'">
                                   <div class="pe-3">
                                     This report will contain all issues from date when report is generated
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row-gap-align row-gap-align-tab-splcases">
                            <label class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 mt-2 text-end">{{'rpt_special_cases_lbl'|translate}}</label>
                            <div class="col-md-9 row-gap-fold-align">
                                <table class="classTable black mt-1">
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="highImpactA($event)" inputId="hA"
                                                 formControlName="highImpactA">
                                                <label for="hA" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_hiClassA_lbl'|translate}}
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="highImpactB($event)" inputId="hB"
                                                 formControlName="highImpactB">
                                                <label for="hB" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_hiClassB_lbl'|translate}}
                                                </label>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                            <input type="checkbox" (click)="highImpactI($event)" inputId="hI"
                                                 formControlName="highImpactI">
                                                <label for="hI" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_class_i_lbl'|translate}}
                                                </label>
                                                </div>
                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="highImpact($event)" inputId="h"
                                                 formControlName="highImpact">
                                                <label for="h" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_hi_lbl'|translate}}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="rca($event)" inputId="rca"
                                                 formControlName="rcaRequired">
                                                <label for="rca" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_rcareq_lbl'|translate}}
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="verification($event)" inputId="verification"
                                                 formControlName="verificatonRequired">
                                                <label for="verification" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_vercoord_lbl'|translate}}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="containtment($event)" inputId="containtmentAction"
                                                 formControlName="containtmentAction">
                                                <label for="containtmentAction" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'cc_opex_spcs_containment_lbl'|translate}}
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="check-action2">
                                                <input type="checkbox" (click)="unconfirmed($event)" inputId="unconfirmed"
                                                 formControlName="unconfirmed">
                                                <label for="unconfirmed" class="checkbox-labels label-alignment-all-in-one-report ms-1">
                                                     {{'rpt_special_cases_unconfirmed_lbl'|translate}}
                                                </label>
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="form-group row-gap-align row-gap-align-tab-columns" >
                            <label for="columns"
                                class="col-sm-3 label-alignment-all-in-one-report control-label pe-3 text-end mt-1">{{'rpt_report_columns_lbl'|translate}}</label>
                            <div class="col-sm-9 mr-5 row-gap-fold-align">
                                <div class="multiselectWidth">
                                    <p-multiSelect class="custom-ms border-0 p-multiselect p-component" [options]="allInOneData"
                                        defaultLabel="{{'rpt_cmp_status_ph'|translate}}" [showToggleAll]="false" [resetFilterOnHide]="true"
                                        optionLabel="name" optionValue="code" field="name" (onChange)="changeColumnTabOne($event)" display="chip" [filter]="true" [dropdownIcon]="'none'"
                                        formControlName="columns">
                                    </p-multiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Tab 2 Generate for CCRP Number. -->
        <div *ngIf="currentSelectedTab === constVariables.tab.byCcrpNo">
            <div class="panel panel-default border-top-0 border-bottom-0 mb-0 shadow-none">
                <div class="panel-body pb-0">
                    <div class="col-md-12">
                        <div class="form-group row-gap-align">
                                <label class="col-sm-2 pe-3 text-end control-label mb-0 mt-1">{{'rpt_ccrp_mode_selection'|translate}}</label>
                            <!-- Mode of ccrp selection -->
                            <div class="col-sm-9 ccrpNoRadioButton header-align-mobile">
                                <a class="btn btn-default" role="button" (click)="onClickenterCCRPManually()"
                                    [style.backgroundColor]="this.btnColorChange ? '#d9d9d9': '#ffffff'">
                                    <span class="fa"
                                        [ngClass]="this.btnColorChange ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                    {{'rpt_enter_ccrp_manually'|translate}}
                                </a>
                                <a  #createUploaderIssueDesc class="btn btn-default  mx-2"
                                     role="button" (click)="onClickuploadCCRPFile()"
                                    [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                                    <span class="fa"
                                        [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                    {{'rpt_upload_ccrp_file'|translate}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- Enter CCRP Number Manually -->
                    <div class="col-md-12 help-employee" *ngIf="enterCCRPManually">
                        <div class="form-group row-gap-align mb-1">
                            <label
                                class="col-sm-2 control-label pe-3 mt-1 ccrp-tab-lbl text-end enterCCRPlabel ccrp-no-lbl-align">
                                {{'rpt_ccrpno_lbl'|translate}}
                                <span class="asteriskRequired"> </span>
                            </label>
                            <div class="col-sm-9">
                                <div class="autoComplete-field_ccrpNo">
                                    <p-autoComplete [forceSelection]="true"
                                        class="autoCompleteCustom text-width1 border-0 mt-2" styleClass="CCRPNumber"
                                        formControlName="FilterCCRPTicketNumber" [multiple]="true" type="text"
                                        [suggestions]="filteredIssueTicketNumber" [showEmptyMessage]="true" emptyMessage="No match found!"
                                        (completeMethod)="filterCCRPTicketNumber($event)" field="cno"
                                        (onSelect)="ccrpSelect($event)" (onUnselect)="ccrpUnselect($event)">
                                    </p-autoComplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Upload CCRP file -->
                    <div class="col-md-12"  *ngIf="uploadCCRPFile">
                        <div class="form-group row-gap-align">
                            <label
                                class="col-sm-2 control-label ccrp-tab-lbl pe-3 mt-1 text-end enterCCRPlabel tab-lg-upload">
                                {{'rpt_ccrp_file_lbl'|translate}} <span class="asteriskRequired"> </span>
                            </label>

                            <div class="col-md-8 col-sm-9 p-0 selectfile">
                                <p-fileUpload #fileUpload #createUploader type="file" name="files[]" chooseLabel="Select File"
                                    [showCancelButton]="true" chooseIcon="pi pi-paperclip" removeStyleClass="p-overlaypanel1"
                                    [multiple]="false" chooseStyleClass="p-overlaypanel imageTooltip" [disabled]="fileUploadSelectBtn"
                                    [maxFileSize]="60000000" [auto]="true" class="" (onSelect)="myUploader(fileUpload,$event)"

                                    [customUpload]="true" pTooltip="Excel should have only one column (CCRP NO.) on first sheet with CCRP NO. as header
                                            <img src='../../../../assets/images/DemoExcelForAllInOne.png'/>"
                                    [escape]="false" tooltipPosition="left" appendTo="body"
                                    tooltipStyleClass="tooltipFileUploadReports">
                                    <ng-template let-file let-index pTemplate="file">
                                        <ul class="p-upload-files p-reset">
                                            <li class="p-file row">
                                                <div class="col-md-11 col-sm-11" [pTooltip]="file?.name" [escape]="false"
                                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                                    <span class="p-filename">{{file?.name}}</span>
                                                </div>
                                                <div class="col-md-1 col-sm-1 text-end" [pTooltip]="removeText" [escape]="false"
                                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                                        <i class="fa fa-window-close closebtn" aria-hidden="true" (click)="removeFile(file , fileUpload)"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </p-fileUpload>

                            </div>
                        </div>

                    </div>
                    <div class="row" *ngIf="uploadCCRPFile">
                      <span class="error" *ngIf="inAppropriateExcelFile">Please select excel file only</span>
                      <span class="error" *ngIf="excelColumnFormatWarning">The format of selected file is not correct. Excel file should
                        have only one column (CCRP No.) on first sheet with CCRP No. as header.</span>
                      <span class="error" *ngIf="maximumCCRP">Uploaded file can have maximum 500 CCRPs</span>
                    </div>
                </div>
            </div>
            <!-- Reports Column -->
            <div class="panel-body col-md-12 pt-0 ">
                <div class="form-group" ng-show="visible.columns.allInOne">
                    <label class="col-sm-2 control-label  report-col-tab text-end pe-3 mt-1">{{'rpt_report_columns_lbl'|translate}}</label>
                    <div class="col-sm-9">
                        <div>
                            <div class="multiselectWidth">
                                <p-multiSelect class="custom-ms border-0 p-multiselect p-component border-0"
                                    formControlName="reportsColumn" optionValue="code" field="name" [resetFilterOnHide]="true"
                                    [showToggleAll]="false" [options]="allInOneData" display="chip"
                                    optionLabel="name" [filter]="true" [dropdownIcon]="'none'" (onChange)="changeColumnTabTwo($event)">
                                </p-multiSelect>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div >
        <div class="row mb-4 btn-primary-red">
            <div class="pull-right " style="text-align: right;">
                <i class="fa fa-info-circle tooltip-wrapper mx-1"
                    style="padding-top: 0.6em; margin-right: 10px;display: inline;"
                    title="{{'cc_report_data_refresh_info'|translate}}" tooltip-trigger="mouseenter"
                    tooltip-placement="top"></i>
                <button id="clearFilter" class="widget-buttonlarge me-2"
                    (click)="clear()">{{'rpt_nav_clear'|translate | uppercase}}</button>
                <button id="saveFilter" [disabled]="this.btnColorChange2" class="widget-buttonlarge me-2"
                    (click)="showSaveSettingPopup()">{{'rpt_nav_save_settings'|translate | uppercase}}</button>
                <button (click)="exportToExcel()"
                        [disabled]="!(this.reportsForm.get('FilterCCRPTicketNumber').value !=null && this.reportsForm.get('FilterCCRPTicketNumber').value != '' || this.selectedFileCheck!=null && this.selectedFileCheck != '' || this.currentSelectedTab == 1 )"
                        class="widget-buttonlarge me-2" *ngIf="!IsExcelGenerating"
                        [ngClass]="(this.reportsForm.get('FilterCCRPTicketNumber').value !=null && this.reportsForm.get('FilterCCRPTicketNumber').value != '' || this.selectedFileCheck!=null && this.selectedFileCheck != '' || this.currentSelectedTab == 1 )? '': 'grayButton'">{{'rpt_nav_export'|translate | uppercase}}</button>
                <button class="widget-buttonlarge me-2" *ngIf="IsExcelGenerating"><i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...</button>
                <!-- View Button -->
                <button class="widget-buttonlarge me-2" (click)="viewReport()"
                [disabled]="!(this.reportsForm.get('FilterCCRPTicketNumber').value !=null && this.reportsForm.get('FilterCCRPTicketNumber').value != '' || this.selectedFileCheck!=null && this.selectedFileCheck != '' || this.currentSelectedTab == 1 )"
                [ngClass]="(this.reportsForm.get('FilterCCRPTicketNumber').value !=null && this.reportsForm.get('FilterCCRPTicketNumber').value != '' || this.selectedFileCheck!=null && this.selectedFileCheck != '' || this.currentSelectedTab == 1 )? '': 'grayButton'"
                target="_blank">{{'rpt_nav_view'|translate | uppercase}}</button>

            </div>
        </div>

        <div class="table-responsive">

            <table id="savedFilters" class="table table-condensed table-hover"
                *ngIf="reportSettings && reportSettings?.length > 0">
                <thead>
                    <tr>
                        <th class="col-md-3 table-header-name">{{'rpt_prm_h_name'|translate}}</th>
                        <th class="col-md-5 table-header-info">{{'rpt_prm_h_info'|translate}}</th>
                        <th class="col-md-1 table-header-default">{{'rpt_prm_h_default'|translate}}</th>
                        <th class="col-md-3"></th>
                        <!-- <th class="col-sm-2"></th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of reportSettings" class="tr-height">
                        <td id="sfname">
                            {{c.name}}
                        </td>
                        <td id="sfdate">
                            {{'rpt_rpm_created'|translate}}: {{c.date | date : 'yyyy-MM-dd hh:mm:ss'}}<br>
                            <div *ngIf="c?.to[0]?.email">
                                {{'rpt_prm_emailnotifiwillbesendto'|translate}}: <span
                                    *ngFor="let a of c.to; let last = last;">
                                    {{a.email}}{{last? '' : ', '}}
                                </span>
                            </div>
                        </td>
                        <td id="sfdefault">
                            <i class="fa fa-check" *ngIf="c.isDefault"></i>
                        </td>
                        <td class="text-right all-in-one-table-details remove-px">
                            <button id="sfapply" class="widgetButtonMedium btn-align-firefox"
                                (click)="show(c.dataField)">{{'rpt_prm_view_btn'|translate}}</button>
                            <button id="sfedit" class="widgetButtonMedium ms-1"
                                (click)="editSettings(c)">{{'rpt_prm_edit_btn'|translate}}</button>
                            <button id="sfremove" class="widgetButtonMedium ms-1 remove-ms"
                                (click)="remove(c)">{{'rpt_prm_remove_btn'|translate}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>



        </div>
    </div>
</form>
