export class maxLengthConstants {
    public static PRODUCT_SERIAL_NO = 200;
    public static PROJECT_NAME = 200;
    public static PROJECT_NUMBER = 200;
    public static CUSTOMER_REFERENCE_NUMBER = 200;
    public static REDCARD_ID = 25;
    public static SUBJECT = 40;
    public static WHAT_ISSUE_DESC = 500;
    public static WHY_ISSUE_DESC = 255;
    public static HOW_ISSUE_DEC = 255;
    public static WHERE_ISSUE_DESC = 255;
    public static WHEN_ISSUE_DESC = 255;
    public static WHO_ISSUE_DESC = 255;
    public static ORGANIZATION_NEEDED_ISSUE_DESC = 255;
    public static ISSUE_TARGET_ISSUE_DESC = 4000;
    public static FAILURE_MODE_DESC = 255;
    public static WHAT_IS_CUSTOMER_EXPECTED_RESOLUTION_DESC = 500;
    public static PURCHASE_ORDER_NO = 100;
    public static IMPACT_ON_INSTALL_BASE = 100;
    public static TOTAL_INSTALL_BASE = 100;
    public static OTHER_DOCUMENT_ID = 250;
    public static ITEM_DESIGNATION = 250;
    public static ARTICLE_NUMBER = 250;
    public static Q_NUMBER = 250;
    public static Q_NUMBER_RESOLVING = 250;
    public static NO_REPORT_JUSTIFICATION = 1000;
    public static SPECIFICATION_NAME = 1000;
    public static HOW_PRODUCT_FAIL_MEET_SPEC = 1000;
    public static CATEGORY_KEYWORD = 500;
    public static JUSTIFICATION_CURRENT = 1000;
    public static EIGHT_D_JUSTIFICATION_CURRENT = 1000;
    public static CUSTOMER_FEEDBACK_JUSTIFICATION = 1000;
    public static REVIEW_REQUIRED_JUSTIFICATION = 1000;
    public static WHERE_WAS_ISSUE_DETECTED_ID = 20;
    public static CONTAINMENT_ACTION_EXPLAINATION = 1000;
    public static EFFECTIVENESS_REASON = 1000;
    public static ACTION_NAME_RCA = 100;
    public static CHANGE_DESCRIPTION=250;
    public static LESSONS_LEARNT = 4000;
}
