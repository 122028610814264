// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '1.0.0',
  appInsights: {
    instrumentationKey: '8d043596-4df5-4af2-b42e-09e032ee4a21'
  },
  SNCRPURL: 'https://stage.sncrp.hitachienergy.com/',
  API_URL: 'https://ccrpcoreapi.azurewebsites.net/',
  default_culture: '/en',
  reports:{
    analyzerReportPath:'/files/CCRP Analyzer DEV New.xlsx',
    analyzerArchiveReportPath:'/files/CCRP Analyzer DEV(Archive).xlsx',
    analyzerOldReportPath:'/files/CCRP Analyzer DEV Old.xlsx',
  },
  azure: {
    uiUrl:'https://dev.ccrp.tsa.hitachienergy.com/',
    baseUrl: 'https://dev.api.ccrp.tsa.hitachienergy.com/',
    scopeUri: ['api://9b56c328-04fd-441f-9f13-6e05e8d00cca/api.scope'],
    tenantId: '7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195',
    uiClienId: '425e1e3a-0508-4048-887d-8d8b807695cf',
    redirectUrl: window.location.origin,
    endpoint: {
      root: 'https://graph.microsoft.com/v1.0',
      profile: '/me',
      profilePhoto: '/me/photo'
    },
  },
  idsForHighClassApproval: ['klaus.lehan@hitachienergy.com', 'lutz-detlef.haas@hitachienergy.com', 'kiran.yarasi@hitachienergy.com'],
  sessionIdleTime: 900,
  sessionLogoutTime: 1800,
  displayActionNotification:true,
  notificationLabel:"",
  notificationValue:"",
  ncrLiveDate:'2023-10-16 00:00:00',
  lessonsLearntLiveDate:'2025-01-08 00:00:00'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
